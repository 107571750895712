/**
 * Created by buddy on 2020-07-25.
 */
export enum WALLET_STATUS {
	NOT_INSTALLED,
	LOCKED,
	NOT_CONNECTED,
	NO_IDENTITY,
	SINGED,
}

export const WALLET_STATUS_TEXT = {
	[WALLET_STATUS.NOT_INSTALLED]: 'NOT 	INSTALLED',
	[WALLET_STATUS.LOCKED]: 'LOCKED',
	[WALLET_STATUS.NOT_CONNECTED]: 'NOT CONNECTED',
	[WALLET_STATUS.SINGED]: 'SIGNED',
	[WALLET_STATUS.NO_IDENTITY]: 'NO IDENTITY',
};
