import React from 'react';
import styled from 'styled-components';
import Popover, { PopoverProps } from '../Popover';

const TooltipContainer = styled.div`
	width: 228px;
	padding: 0.6rem 1rem;
	line-height: 150%;
	font-weight: 400;
`;

interface TooltipProps extends Omit<PopoverProps, 'content'> {
	text: string;
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
	return <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />;
}
